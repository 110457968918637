var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.data && !_vm.loading)?_c('tfoms-panel',_vm._b({attrs:{"title":"Результаты экспертизы","small":""}},'tfoms-panel',_vm.$attrs,false),[_c('div',{staticClass:"analysis-tfoms_expertise-table analysis-tfoms__table analysis-tfoms__base-table"},[_c('base-table-full',{attrs:{"dataSource":_vm.data,"headers":[
          { text: 'Полис', value: 'Polis' },
          {
            text: 'Дата экспертизы',
            value: 'ExpertiseDate',
            dataType: 'Date',
            displayText: (e) => this.$options.filters.DateShort(e),
          },
          { text: 'Вид', value: 'KindText' },
          { text: 'Код', value: 'ViolationCode' },
          { text: 'Наименование', value: 'Name' },
          {
            text: 'Неоплата',
            value: 'NonPaymentValue',
            align: 'center',
            dataType: 'string',
            displayText: (e) => this.$options.filters.numberSplit(e),
          },
          {
            text: 'Штрафы',
            value: 'FineValue',
            align: 'center',
            dataType: 'string',
            displayText: (e) => this.$options.filters.numberSplit(e),
          },
        ],"hide-default-footer":"","notShowSelect":"","exclude-settings-actions":['table', 'group'],"sort-by":['ExpertiseDate']},on:{"click:row":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([{key:"item.ExpertiseDate",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("DateShort")(item.ExpertiseDate))+" ")]}},{key:"item.NonPaymentValue",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("numberSplit")(item.NonPaymentValue))+" ")]}},{key:"item.FineValue",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("numberSplit")(item.FineValue))+" ")]}}],null,false,3845712464)})],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }